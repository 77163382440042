window.calendarPopup = ( element , jqEvent ,popup_selector ) =>
  jqEvent.preventDefault()
  popup_x = $(jqEvent.target).position().left;
  popup_y = $(jqEvent.target).position().top + $(jqEvent.target).height();
  $(element).next(popup_selector).css("top",popup_y + "px").css("left",popup_x + "px").fadeIn 75

bindEventHandlers = ->
  $('div.activity-calendar-booked-day').on 'click' , ( jqEvent ) ->
    jqEvent.preventDefault()
    details_div_id = $(this).attr( 'id' ) + "_details"
    calendarPopup this , jqEvent , "##{details_div_id}"

  $('.activity-calendar-close').on 'click' , (jqEvent) ->
    jqEvent.preventDefault()
    $(this).parent().fadeOut 75

  $('div.calendar-day').on 'click' , (jqEvent) ->
    jqEvent.preventDefault()
    $( '.new-lead-box' ).hide()
    calendarPopup this , jqEvent , 'div.new-lead-box'

  $('div.activity-block').on 'click' , (jqEvent) ->
    jqEvent.preventDefault()
    position = $(jqEvent.target).position()
    if $(this).next('div.activity-details').is( ':visible' )
      $(this).next('div.activity-details').fadeOut 75
    else
      $(this).next('div.activity-details').css("top" , (position.top + 71) + "px").css("left" , (position.left + 50) + "px").fadeIn 75

  $('div.lead-block').on 'click' , (jqEvent) ->
    jqEvent.preventDefault()
    position = $(jqEvent.target).position()
    if $(this).next('div.leads-details').is( ':visible' )
      $(this).next('div.leads-details').fadeOut 75
    else
      $(this).next('div.leads-details').css("top" , (position.top + 71) + "px").css("left" , (position.left + 50) + "px").fadeIn 75


  $('div#calendar-filter-options input').on 'click' , (jqEvent) ->
    $('#filter_calendar_form').submit()

  $('#filter_calendar_form select').change ->
    $('#filter_calendar_form').submit()
  $('.date-field').datepicker { dateFormat: 'yy-mm-dd' }
  $('.search-date-field').datepicker { dateFormat: 'yy-mm-dd' }
  $('.date-time-field').datetimepicker { ampm: true, stepMinute: 15 }
  `$('.darkroast-form').each( function( idx , item ) {
    new window.DarkroastForm( $(item) );
  });`


$(document).ready =>
  bindEventHandlers()
  checkStatus = ->
    if $( '#lead_status' ).val() == 'Not Interested'
      $( '#reason_not_interested' ).show()
    else
      $( '#reason_not_interested' ).hide()

  checkStatus()
  $('[data-dropdown=true]').click ( jqEvent ) ->
    jqEvent.preventDefault()
    dropdown_id = $(this).data 'dropdown-id'
    $("##{dropdown_id}").fadeToggle 100
  $('#lead_status').change ->
    checkStatus()
    status = $(this).val()
    if status == 'Booked'
      $('#event_fields').fadeIn 100
      $('#label_event_title').html('* Title:')
      $('#label_event_start_time').html('* Event Start Time:')
    else
      $('#event_fields').fadeOut 100
      $('#label_event_title').html('Title:')
    if status == 'Booked' || status == 'Temporary Hold'
      $('#label_event_start_date').html('* Date:')
    else
      $('#label_event_start_date').html('Date:')
  $('#calendar_filter_form input' ).click ->
    $("div[data-activity-type=#{$(this).data('activity-type')}]").toggle()
    $('#calendar_filter_form').submit()

  $( '.activity_create_new_lead' ).change ->
    if @.checked
      $( "##{ $( @ ).data( 'form-id' ) }" ).find( '.lead_info' ).show()
    else
      $( "##{ $( @ ).data( 'form-id' ) }" ).find( '.lead_info' ).hide()

  $('#room_dbec').change ->
    $('#brewery_options input').removeAttr('checked').attr('disabled', true)
    $('#event_center_options input').attr('disabled', false)
    $('#lead_event_room_ids__3').attr('checked', true)

  $('#room_dbbc').change ->
    $('#event_center_options input').removeAttr('checked').attr('disabled', true)
    $('#brewery_options input').attr('disabled', false).attr('checked', true)

  $('#brewery_options input').change ->
    $('#event_center_options input').attr('checked', false).attr('disabled', true)

  $('#event_center_options input').change ->
    $('#brewery_options input').attr('checked', false).attr('disabled', true)

