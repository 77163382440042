$(document).ready ->
  $('.overlay-toggle-link').click ( jqEvent ) ->
    jqEvent.preventDefault()
    toggle_id = '#' + $(this).data 'toggle'
    popup_x = $(jqEvent.target).position().left + $(jqEvent.target).width();
    popup_y = $(jqEvent.target).position().top;
    $(toggle_id).css("top",popup_y + "px").css("left",popup_x + "px").fadeToggle 75
    
  # For vendor edit button
  $( '.vendor-links' ).click ->
    false
    
  loadAjax = ( link , type , data ) ->
    group = link.parent()
    $.ajax(
      url: link.data( 'url' )
      type: type
      dataType: 'text'
      data: data
      success: ( response ) ->
        $( '#' + group.data( 'target' ) ).html( response )
        $( '.' + group.data( 'group' ) ).each ->
          button = $( @ )
          button.toggle()
          button.css( { 'display' : 'inline-block' } ) if button.is( ':visible' )
      error: ( xhr , status , msg ) ->
        console.log [status , msg].join( ' : ' )
    )
    
  $( '.edit-link , .cancel-link' ).click ->
    loadAjax $( @ ) , 'GET' , ''
    
  $( '.save-link' ).click ->
    loadAjax $( @ ) , 'PUT' , $( '#' + $( @ ).data( 'form-id' ) ).serialize()