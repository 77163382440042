window.bindCancelButtons = ->
  $('.inline-cancel').click ->
    toggle_inline $(this)
    reset_form $(this).data( 'form' )
    false
  $( '.cancel-iframe' ).click ->
    `with(window.parent)
    {
      $.fancybox.close();
    }`
    false
  $('.cancel').click ->
    if $(this).data('item-id')
      $($(this).data('item-id')).hide()
    $.fancybox.close()
    if $(this).form
      $(this).form.reset();
    false

window.bindActionButtons = ->
  $(document).on 'click', '.form-toggle' , ( jqEvent ) ->
    jqEvent.preventDefault()
    $($(this).data 'toggle' ).toggle()
    false
  $(document).on 'click', '.section-toggle' , ( jqEvent ) ->
    jqEvent.preventDefault()
    text = $(this).text()
    toggle_id = $(this).data 'toggle'
    if text == 'Show'
      $(this).text 'Hide'
      $(toggle_id).slideDown()
    else
      $(this).text 'Show'
      $(toggle_id).slideUp()

    false

  $('.inline-form-toggle').click ->
    toggle_inline $(this)
    false
  $('.showhide').click ->
    obj = $(this).children()
    toggle_id = obj.data 'toggle'
    if obj.text() == 'Show'
      obj.html( 'Hide<i class="icon-chevron-up"></i>' )
      $(toggle_id).slideDown()
    else
      obj.html( 'Show<i class="icon-chevron-down"></i>' )
      $(toggle_id).slideUp()
    false

$(document).ready ->
  $('.date-field').datepicker({ minDate: 0, dateFormat: 'yy-mm-dd' })
  $('#lead_event_date').datepicker({ minDate: 0, dateFormat: 'yy-mm-dd' })
  $('.date-time-field').datetimepicker { ampm: true, stepMinute: 15 }
  $('.search-date-field').datepicker({ dateFormat: 'yy-mm-dd' })

  bindCancelButtons()
  bindActionButtons()
  $('.fancy-form').fancybox {
    autoSize: true,
    closeClick: false
  }
  $('.fancy-form-message').fancybox {
    autoSize: true,
    closeClick: false,
    type: 'iframe',
    width: 400,
    height: 300
  }
  $('.fancy-form-vendor-contact').fancybox {
    autoSize: true,
    closeClick: false,
    type: 'iframe',
    width: 400,
    height: 440
  }
  $('#messages_area > form').submit ->
    $(this).children('.asset-display-area').empty()
    true
  $('#messages_area > form > a').click ->
    $(this).parent().find('.asset-display-area').empty()
    true

$(document).ready ->
  $('.modal-form-primary').fancybox {
      autoSize: true,
      closeClick: false,
      type: 'iframe',
      width: 460,
      height: 600
    }