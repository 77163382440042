window.message_toggle = ( jqEvent ) ->
  jqEvent.preventDefault()
  # alert 'hi'
  details_selector = $(this).data 'toggle'
  $(details_selector).slideToggle()
  $(this).toggleClass 'closed open'
  parent_selector = $(this).data 'parent-selector'
  $("#{parent_selector} span.message-excerpt").toggle()
  # set the message as read
  # unless $(parent_selector).data( 'read' )
  #   message_id = parent_selector.replace '#message_' , ''
  #   jQuery.ajax { type: 'PUT' , url: "/admin/messages/#{message_id}/message_read.js" }
    
$(document).ready -> 
  # $('.message-toggle').live 'click' , message_toggle
  # $('tr.message-display-row td').live 'click' , (jqEvent) ->
  #   id = $(this).parent().data 'id'
  #   $("#message_#{id}_body").slideToggle()
  # 
  #   unless $(this).parent().data( 'read' )
  #     jQuery.ajax { type: 'PUT' , url: "/admin/messages/#{id}/message_read.js" }
  #     $(this).parent().toggleClass 'message-read'
  #     $(this).parent().toggleClass 'message-unread'
  #     $(this).parent().data 'read' , 'true'
      
  $(document).on "ajax:beforeSend", 'form.new_message' , (evt , xhr , settings ) ->
    $(this).find('div.spinner').hide()
  $(document).on 'ajax:complete', 'form.new_message' , (evt , xhr , settings ) ->
    $(this).find('div.spinner').hide()
  $('.message-tags').each (idx , item ) =>
    $(item).tokenInput '/admin/message-tags.json' , { 
                        crossDomain: false, 
                        prePopulate: $(item).data( 'pre' ), 
                        theme: 'facebook' }
  