# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
toggle_item = (item_id) -> item.toggle

window.hideAllMenus = ->
  $('#subnav ul').hide()

window.showEventsMenu = ->
  hideAllMenus()
  $('#events_subnav').show()
  
window.showGalleryMenu = ->
  hideAllMenus()
  $('#gallery_subnav').show()
  
window.showRentalMenu = ->
  hideAllMenus()
  $('#rental_subnav').show()
  
window.showLocationMenu = ->
  hideAllMenus()
  $('#location_subnav').show()
  
window.showCalendarMenu = ->
  hideAllMenus()
  $('#calendar_subnav').show()

window.showContactMenu = ->
  hideAllMenus()
  $('#contact_subnav').show()