`if(!Object.keys) Object.keys = function(o){
 if (o !== Object(o))
      throw new TypeError('Object.keys called on non-object');
 var ret=[],p;
 for(p in o) if(Object.prototype.hasOwnProperty.call(o,p)) ret.push(p);
 return ret;
}`
jQuery ->
  $('.darkroast-form').each (idx, item ) ->
    new window.DarkroastForm $(item)
    true

class window.DarkroastForm
  constructor: (@form) ->
    if @form and not $(@form).data 'darkroast-present'
      $(@form).data 'darkroast-present' , true
      $(@form).bind 'submit' , @submitHandler
      @model_name = $(@form).data 'model-name'
      @hide_form = $(@form).data 'darkroast-hide'
      @reset_form = $(@form).data 'darkroast-reset'
      @templates = {};

  submitHandler: =>
    form_params = $(@form).serialize()
    form_action = $(@form).attr 'action'
    hide_notification()
    hide_errors()
    show_spinner()
    $(@form).children( 'input[type=submit]' ).attr 'disabled' , 'disabled'
    jQuery.ajax( form_action , {
      type: 'POST' ,
      data: form_params,
      dataType: 'json',
      success: @success ,
      complete: @request_complete
    })
    false

  success: ( data , textStatus , jqXhr ) =>
    hide_spinner()
    display_notification data.message
    $(@form).children( 'input[type=submit]' ).removeAttr 'disabled'
    updates = data.updates
    deletes = data.deletes
    if deletes
      handle_deletes deletes
    if updates
      keys = Object.keys data.updates
      handle_updates updates , key for key in keys

    if @hide_form is true
      hide_form = =>
        $(@form).slideUp()
      setTimeout hide_form , 500
    if @reset_form is true
      $(@form)[0].reset()
    true

  request_complete: ( jqXHR , textStatus ) =>
    errors = null
    hide_spinner()
    $(@form).children( 'input[type=submit]' ).removeAttr 'disabled'
    switch jqXHR.status
      when 422
        # notify the user that they submitted an invalid object
        display_notification "One or more fields were invalid."
        # convert the response into an object
        # since the app will be returning the errors this way
        errors = eval( '(' + jqXHR.responseText + ')' )
        keys = Object.keys errors
        @show_error errors , key for key in keys
      when 500
        display_notification()
    true

  handle_updates: (update_items , key ) =>
    sub_items = update_items[key]

    # grab the Handlebars.js template
    template = if @templates[key]
      @templates[key]
    else
      # the template isn't in the list so we need to find, compile and add it
      raw_template = $("##{key.replace( /_/g , '-')}-template").html()
      compiled_template = Handlebars.compile raw_template
      @templates[key] = compiled_template

    # remove any existing items if we want to do a complete replacement
    if sub_items.action == 'replace'
      $("##{key}").empty()

    switch sub_items.action
      when 'replace' , 'append'
        if $( "##{key}" )
          compiled_items = ( template data for data in sub_items.items )
          $("##{key}" ).append html for html in compiled_items
      when 'update'
        dom_ids = ( item.dom_id for item in sub_items.items )
        compiled_items = ( template data for data in sub_items.items )
        $("##{dom_ids[i]}").replaceWith html for html, i in compiled_items
    true

  handle_deletes: ( items ) =>
    $("##{id.replace(/^#/,'')}").remove() for id in items

  show_error: (error_object , key) =>
    error_container = $ "##{@model_name}_#{key}_error"
    error_container.empty()
    error_container.text error_object[key].join( ',' )
    error_container.slideDown()
    true
  hide_errors: =>
    $( '.inline-error' ).hide();

  display_notification: (notification = "There was a server error. We've been notified and will fix it soon." ) =>
    notification_area = $(@form).children 'div.notifications'
    notification_area.empty()
    notification_area.text notification
    notification_area.slideDown()
  hide_notification: =>
    $(@form).children( 'div.notifications' ).hide()

  show_spinner: =>
    $(@form).children( 'div.spinner').slideDown()
  hide_spinner: =>
    $(@form).children( 'div.spinner').hide()
